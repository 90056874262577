import React from 'react';
import {Formik, Form, Field, ErrorMessage} from "formik";
import Axios from "axios";
import {useTranslation} from "react-i18next";
import ArrowRight from "../../images/icon-arrow-right.inline.svg";

const FeedbackForm = () => {
  const {t} = useTranslation();

  return (
    <section className="form">
      <div className="form__wrapper wrapper">
        <h2 className="big-title form__title">{t("contact.feedback")}</h2>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            city: "",
            city_add: "",
            message: "",
          }}
          validate={(values) => {
            const errors = {};
            if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = <>{t("errors.invalidEmail")}</>
            }

            if (!values.email) {
              errors.email = <>{t("errors.missingEmail")}</>
            }

            if (!values.name) {
              errors.name = <>{t("errors.missingName")}</>
            }

            if (!/^[0-9]*$/.test(values.phone)) {
              errors.phone = <>{t("errors.onlyNumbers")}</>
            }

            if (!values.message) {
              errors.message = <>{t("errors.addCommentary")}</>
            }

            return errors;
          }}
          onSubmit={(values, {setSubmitting, resetForm, initialValues}) => {
            resetForm({values: initialValues});
            setTimeout(() => {
              const axiosConfig = {
                headers: {
                  "Content-type": "application/json",
                },
              };
              Axios.post(
                "https://ntf.aquaart.tech/aquaart/forms",
                values,
                axiosConfig
              )
                .catch((e) => {
                  console.log(e);
                })
                .finally(() => {
                  console.log("Данные прошли!");
                  setSubmitting(false);
                  document
                    .querySelector(".modal-wrapper")
                    .classList.add("modal-wrapper--active");
                  document
                    .querySelector(".modal__close")
                    .addEventListener("click", function () {
                      document
                        .querySelector(".modal-wrapper")
                        .classList.remove("modal-wrapper--active");
                    });
                });
            }, 400);
          }}
        >
          {({isSubmitting}) => (
            <Form>
              <div className="form">
                <div className="form__half-section">
                  <div className="form__full-row">
                    <label className="form__label-item form__label-item--required" htmlFor="name">
                      <Field className="form__input" type="text" name="name"
                             placeholder={t("contact.namePlaceholder")}/>
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-div"
                      />
                    </label>
                  </div>
                  <div className="form__full-row">
                    <label className="form__label-item form__label-item--required" htmlFor="email">
                      <Field className="form__input" type="email" name="email"
                             placeholder={t("contact.emailPlaceholder")}/>
                    </label>
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error-div"
                    />
                  </div>
                  <div className="form__full-row">
                    <label className="form__label-item" htmlFor="phone">
                      <Field className="form__input js-phone" type="text" name="phone"
                             placeholder={t("contact.phonePlaceholder")}/>
                    </label>
                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="error-div"
                    />
                  </div>
                  <div className="form__full-row form__city-row">
                    <label className="form__label-item form__label-item--city" htmlFor="city">
                      <Field className="form__input select"
                             as="select"
                             name="city"
                      >
                        <option value="" disabled selected>
                          {t('contact.cityPlaceholder')}
                        </option>
                        //TODO: Нужен список городов
                        <option value="Lviv">Lviv</option>
                        <option value="Kyiv">Kyiv</option>
                      </Field>
                    </label>
                    <label className="form__label-item" htmlFor="city_add">
                      <Field className="form__input form__input--city-add" type="text" name="city_add"
                             placeholder={t("contact.cityAddPlaceholder")}/>
                    </label>
                  </div>
                </div>
                <div className="form__half-section">
                  <div className="form__full-row">
                    <label className="form__label-item form__label-item--required" htmlFor="message">
                      <Field className="form__input form__input--textarea" type="text" as="textarea" name="message"
                             placeholder={t('contact.messagePlaceholder')}/>
                    </label>
                    <ErrorMessage
                      name="message"
                      component="div"
                      className="error-div"
                    />
                  </div>
                </div>
              </div>
              <div className="form-submit">
                <div className="form__full-row">
                  <div className="form__tooltip-wrapper">
                    <p className="form__tooltip">
                      {t('contact.tip')}
                    </p>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="form__submit"
                    >
                      {t('contact.sendButton')}
                      <ArrowRight/>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};

export default FeedbackForm;
