import React from 'react';
import {useTranslation} from "react-i18next";

const ContactLead = () => {
  const { t } = useTranslation();

  return (
    <section className="contact-lead">
      <div className="contact-lead__wrapper wrapper">
        <h1 className="big-title contact-lead__title">{t("contact.title")}</h1>
        <div className="contact-lead__section">
          <div className="contact-lead__text-wrapper">
            <p>{t("contact.leftText")}</p>
          </div>
          <div className="contact-lead__data">
            <p className="contact-lead__phone">{t("contact.phone")}</p>
            <p className="contact-lead__email">{t("contact.email")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactLead;
