import React from 'react';
import ContactLead from "../components/contacts/contact-lead";
import FeedbackForm from "../components/contacts/feedback-form";
import Header from "../components/sections/header";
import Footer from "../components/sections/footer";

const Contact = () => {
  return (
    <>
      <Header/>
      <ContactLead />
      <FeedbackForm />
      <Footer/>
    </>
  );
};

export default Contact;
